<template>
  <div ref="page_box" :class="weixin || 'page'">
    <div ref="top_box" class="header_box" v-if="!weixin">
      <div style="position: relative;">
        <img src="../../assets/back_arrow.png" alt="" class="arrow" @click="goBack">
        排行榜
      </div>
    </div>
    <div class="ranking_title">
      <div class="left_box">当前排名</div>
      <div class="center_box">用户名</div>
      <div class="right_box">得分</div>
    </div>
    <div class="ranking_main">
      <template v-for="(item,index) in rankList">
        <div :key="index + '排行榜'" class="ranking_item">
          <div class="left_box">
            <img v-show="index===0" src="../../assets/jinpai.png" alt="">
            <img v-show="index===1" src="../../assets/yinpai.png" alt="">
            <img v-show="index===2" src="../../assets/tongpai.png" alt="">
            <span v-show="index>2" class="ranking_num">{{ index+1 }}</span>
          </div>
          <div class="center_box">
            <img class="user" :src="item.avatarUrl?item.avatarUrl:require('../../assets/user.png')" alt="">
            <div class="user_msg">
              <div class="user_name">{{ item.username }}</div>
              <div class="user_time">{{ item.time_takes }}</div>
            </div>
          </div>
          <div class="right_box">
            <span class="score">{{ item.score }}</span>分
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getRank } from '@/api/home'
export default {
  data() {
    return {
      uid: '',
      part_id: 259,
      rankList: [],
      weixin:this.weixin
    }
  },
  created() {
    this.uid = this.$route.query.uid
    this.part_id = this.$route.query.id
    this.getRankList()
  },
  mounted() {
    this.setiosTop()
  },
  methods: {
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if(this.weixin){
        return false
      }
      if (isiOS) {
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 84 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 64 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    getRankList() {
      const info = {
        uid: this.uid,
        part_id: this.part_id,
        app_type: 1
      }
      getRank(info).then(res => {
        const data = res.result
        this.rankList = data
      })
    },
    goBack() {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
    padding-top: 88px;
}
.ranking_title{
    height: 88px;
    line-height: 88px;
    padding: 0 30px;
    background: #e2e2e2;
    color: #999;
    font-size: 14PX;
}
.left_box{
    width: 150px;
    text-align: center;
    float: left;
}
.center_box{
    padding-left: 86px;
    float: left;
}
.right_box{
    float: right;
    width: 100px;
    text-align: center;
}
.ranking_main{
    padding: 0 30px 40px;
    .ranking_item{
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        .left_box{
            padding-top: 5px;
            img{
                width: 45px;
                height: 60px;
            }
            .ranking_num{
                font-size: 18PX;
                color: #333;
                line-height: 70px;
            }
        }
        .center_box{
            padding-left: 0;
            .user{
                width: 70px;
                height: 70px;
                border-radius: 50px;
                margin-right: 16px;
                float: left;
            }
            .user_msg{
                float: left;
                .user_name{
                    font-size: 14PX;
                    line-height: 20PX;
                    color: #333;
                    font-weight: 600;
                }
                .user_time{
                    font-size: 12PX;
                    line-height: 16PX;
                    color: #999
                }
            }
        }
        .right_box{
            color: #999;
            font-size: 12PX;
            line-height: 70px;
            .score{
                color: #ee2e2e;
                font-size: 16PX;
            }
        }

    }
};

</style>
